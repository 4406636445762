import React, { useState, Fragment, useEffect } from "react"
import { Link } from "gatsby"
import propTypes from "prop-types"

const HoursOption = props => {
  const {
    className,
    link,
    text,
    icon,
    borderColor,
    isCollapse,
    options,
    isOpen,
    toggle,
    onClick,
    pk,
    selected,
    useAllData,
    allData
  } = props
  const [openMenu, setOpenMenu] = useState(isOpen || false)

  useEffect(() => {
    setOpenMenu(isOpen)
  }, [isOpen])

  const toggleSubmenu = () => {
    setOpenMenu(true)

    if (toggle && typeof toggle === "function") {
      toggle(openMenu)
    }
  }

  const renderSubmenu = () => {
    return (
      <ul className={`Hours-submenu ${openMenu ? "open" : ""}`}>
        {renderOptions()}
      </ul>
    )
  }

  const renderOptions = () => {
    return (
      options &&
      options.map((item, index) => (
        <li
          className={`Hours-submenu-item ${item?.icon ? "" : "list-type-disc"
            } ${item?.borderBottom ? "border-b" : ""}`}
          key={`hour-option-${index}`}
        >
          {renderIcon(item?.icon)}
          <Link
            activeClassName="selected"
            className="Hours-submenu-link"
            to={item.link}
          >
            {item.text}
          </Link>
        </li>
      ))
    )
  }

  const renderIcon = icon => {
    if (!icon) return

    return <i className={icon}></i>
  }

  const renderItem = () => {
    if (isCollapse) {
      return (
        <Fragment>
          <button className="Hours-link" onClick={toggleSubmenu}>
            <span className="Hours-text">{text}</span>
            <i className={icon}></i>
          </button>
          {renderSubmenu()}
        </Fragment>
      )
    }

    return (
      <Link
        to={link}
        className={selected ? "Hours-link selected" : "Hours-link"}
        style={{
          borderLeft: `${borderColor ? `10px solid ${borderColor}` : ""}`,
        }}
      >
        <span className="Hours-text">{text}</span>
        <i className={icon}></i>
      </Link>
    )
  }

  return (
    // eslint-disable-next-line  jsx-a11y/no-noninteractive-element-interactions
    <button onClick={onClick(useAllData ? allData : pk)} className={`Hours-item ${className}`}>
      {renderItem()}
    </button>
  )
}

HoursOption.propTypes = {
  link: propTypes.string,
  icon: propTypes.string,
  selected: propTypes.bool,
  isCollapse: propTypes.bool,
  options: propTypes.any,
  isOpen: propTypes.bool,
  toggle: propTypes.func,
  borderColor: propTypes.string,
}

HoursOption.defaultProps = {
  isCollapse: false,
  options: [],
  isOpen: false,
  toggle: () => { },
  borderColor: "",
  className: ""
}

export default HoursOption
