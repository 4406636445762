import React, { useState } from "react"
import PropTypes from "prop-types"
import Hours from "../Hours"

const CollapseMenu = props => {
  const {
    list,
    title,
    className,
    initialCategory,
    getCategory,
    useAllData,
  } = props
  const [toggle, setToggle] = useState(false)

  const handleToggle = () => {
    setToggle(!toggle)
  }

  const handleGetCategory = (data) => {
    getCategory(data)
    handleToggle();
  }

  return (
    <div className={`Collapse-wrapper ${className}`}>
      <div className={`Collapse-wrap-button ${toggle ? "pb-0" : ""}`}>
        <button className="Collapse-button" onClick={handleToggle}>
          {title}
        </button>
      </div>
      <div className={`Collapse-items ${toggle ? "" : "close"}`}>
        <Hours
          list={list}
          showTitle={false}
          className="Collapse-options"
          initialCategory={initialCategory}
          getCategory={handleGetCategory}
          useAllData={useAllData}
        />
      </div>
    </div>
  )
}

CollapseMenu.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  className: PropTypes.string,
}

CollapseMenu.defaultProps = {
  className: "",
}

export default CollapseMenu
