import React, { useEffect, useState } from "react"
import HourOption from "./HoursOption"
import PropTypes from "prop-types"

const Hours = props => {
  const {
    title,
    list,
    className,
    showTitle,
    getCategory: getCategoryProps,
    useGalleryItem,
    initialCategory,
    useAllData
  } = props

  const [itemSelected, setItemSelected] = useState(-1)
  const [selected, setSelected] = useState(-1)

  const getCategory = data => () => {
    setSelected(useAllData ? data.pk : data)
    if (getCategoryProps) {
      getCategoryProps(data)
    }
  }

  useEffect(() => {
    if (!initialCategory) return
    setSelected(initialCategory)
  }, [initialCategory])

  const renderTitle = () => {
    if (!showTitle || !title) return

    return <p className="Hours-title">{title}</p>
  }

  const toggleMenu = index => () => {
    setItemSelected(index)
  }

  const renderOption = () => {
    let options = []
    if (useGalleryItem) {
      options = [...list, { title: "Galeria de imagenes", link: '/actualidad/galerias', pk: -2 }]
    } else {
      options = list
    }
    return (
      options &&
      options.map((item, i) => {
        return (
          <HourOption
            pk={item?.pk}
            onClick={getCategory}
            toggle={toggleMenu(i)}
            isOpen={itemSelected === i}
            text={item.title}
            link={item.link}
            icon={item.icon}
            key={`hours-${i}`}
            isCollapse={!!item.isCollapse}
            options={item?.options}
            selected={item?.pk === selected}
            borderColor={item?.color_hexadecimal}
            allData={item}
            useAllData={useAllData}
          />
        )
      })
    )
  }

  return (
    <div className={`Hours ${className}`}>
      {renderTitle()}
      <ul className="Hours-list">{renderOption()}</ul>
    </div>
  )
}

Hours.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  selected: PropTypes.string,
  useGalleryItem: PropTypes.bool,
  useAllData: PropTypes.bool,
  initialCategory: PropTypes.number
}

Hours.defaultProps = {
  title: "CONSIGUE HORAS DE FORMACIÓN",
  className: "",
  showTitle: true,
  selected: "",
  useGalleryItem: true,
}

export default Hours
